import React, { useEffect, useState } from "react";
import Loading from "../LoaderPageSection/Loading";
import Layout from "../Layout/Content";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { ethers } from "ethers";
import { OldStakeAbi, OldStakeAddress, StackAbi, StackAddress, StakeAbi, StakeAddress } from "../Abi/Content";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";
import { BigNumber } from "bignumber.js";
import UserPageSection from "./Content";
import deodLogo from "../../../image/Logo.png";
import UsdtLogo from "../../../image/tether-logo.png";
import { ismintedOne } from "../../../data";
const UserstakeOne = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [refferal, setRefferal] = useState("");
  const [totalToken, setTotalToken] = useState("0");
  const [totalClaimToken, setTotalClaimToken] = useState("0");
  const [availableClaim, setAvailableClaim] = useState("0");
  const [refferalEarn, setRefferalEarn] = useState("0");
  const [stackingData, setStackingData] = useState([]);
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [referralEarningsWithdrawn, setreferralEarningsWithdrawn] = useState(0);
  const [refferalEarnold, setrefferalEarnold] = useState(0);
  const [withdrawnold, setwithdrawnold] = useState(0);
  let getLocalData = localStorage.getItem("auth");
  getLocalData = JSON.parse(getLocalData);

  const loadData = async () => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const OldStakeContract = new ethers.Contract(OldStakeAddress, OldStakeAbi, signer);
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let totalToken = await OldStakeContract.userTotalTokenStaked(address);
      totalToken = parseFloat(totalToken) / 10 ** 18;
      console.log("totalToken", totalToken);
      setTotalToken(totalToken);

      let refferalEarn = await StakeContract.referralEarnings(address);
      refferalEarn = parseFloat(refferalEarn) / 10 ** 18;
      console.log("refferalEarn", refferalEarn);
      setRefferalEarn(refferalEarn);

      let refferalEarnold = await OldStakeContract.getUserReferalEarning(address);
      refferalEarnold = parseFloat(refferalEarnold) / 10 ** 18;
      console.log("refferalEarn", refferalEarnold);
      setrefferalEarnold(refferalEarnold);

      let withdrawnold = await OldStakeContract.withdrawnAmount(address);
      withdrawnold = parseFloat(withdrawnold) / 10 ** 18;
      console.log("refferalEarn", withdrawnold);
      setwithdrawnold(withdrawnold);


      let availableClaim = await StakeContract.availableAmountForClaim(address);
      availableClaim = parseFloat(availableClaim) / 10 ** 18;
      console.log("availableClaim", availableClaim);
      setAvailableClaim(availableClaim);

      let totalClaimToken = await StakeContract.withdrawnAmountV1(address);
      totalClaimToken = parseFloat(totalClaimToken) / 10 ** 18;
      console.log("totalClaimToken", totalClaimToken);
      setTotalClaimToken(totalClaimToken);

      let referralEarningsWithdrawn = await StakeContract.claimedReferralEarnings(address);
      referralEarningsWithdrawn = parseFloat(referralEarningsWithdrawn) / 10 ** 18;
      console.log("totalClaimToken", totalClaimToken);
      setreferralEarningsWithdrawn(referralEarningsWithdrawn)
      // let totalAmountCount = await StakeContract.userDepositCounts(address);
      // totalAmountCount = parseInt(totalAmountCount);

      // for (let index = 0; index < totalAmountCount; index++) {
      //   let totalAmount = await StakeContract.userDepositIds(address, index);
      //   totalAmount = parseInt(totalAmount);

      //   let totaldepositInfo = await StakeContract.depositInfo(totalAmount);
      //   // totaldepositInfo = parseFloat(totaldepositInfo);
      //   console.log("totaldepositInfo************", totaldepositInfo);
      // }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleClaimAPI = async (trxHash) => {
    const token = auth?.user?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const bodyData = {
      amount: availableClaim,
      transactionHash: trxHash,
    };
    try {
      const resp = await axios.post(`/api/user/stakeV1Withdrawal`, bodyData, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const storeAddress = auth?.user?.address;
      if (address === storeAddress) {
        const StakeContract = new ethers.Contract(
          StakeAddress,
          StakeAbi,
          signer
        );
        const txn = await StakeContract.withdrawTokensV1(address);
        const receipttxn1 = await txn.wait();
        if (receipttxn1.status !== 1) {
          toast.error("Error");
        } else {
          const hash = await receipttxn1.transactionHash;
          await handleClaimAPI(hash);
        }
      } else {
        toast.error("Connect correct wallet");
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
      toast.error(`${JSON.stringify(error.reason)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
    window.location.reload(false);
  };

  const handleClaimReferral = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const storeAddress = auth?.user?.address;
      if (address === storeAddress) {
        const StakeContract = new ethers.Contract(
          StakeAddress,
          StakeAbi,
          signer
        );
        const txn = await StakeContract.claimReferralEarnings();
        const receipttxn1 = await txn.wait();
        if (receipttxn1.status !== 1) {
          toast.error("Error");
        } else {
          const hash = await receipttxn1.transactionHash;
          // await handleClaimAPI(hash);
        }
      } else {
        toast.error("Connect correct wallet");
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
      toast.error(`${JSON.stringify(error.reason)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
    window.location.reload(false);
  };

  const handleGetStackingData = async () => {
    const token = auth?.user?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    try {
      const resp = await axios.get(`/api/user/stakeV1Details`, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          setStackingData(resp?.data?.data);
          setFinalTotalAmount(+resp?.data?.finalTotalAmount);
          console.log("staking Data", resp?.data?.data);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setRefferal(auth?.user?.referralAddress);
      setAddress(auth?.user?.address);
      handleGetStackingData();
    }
  }, [auth]);

  useEffect(() => {
    async function handleData() {
      await loadData();
    }
    handleData();
  }, []);

  useEffect(() => {
    if (getLocalData === null) {
      navigate("/");
    }
  }, [getLocalData]);

  useEffect(() => {
    async function handleGetParaswapPrice() {
      if (totalToken == 0) {
        setUsdtPrice(0);
      } else {
        try {
          let x;
          x = totalToken * 10 ** 18;
          let z = new BigNumber(x);
          let a = z.toFixed();
          // const deodToken = (totalToken * 10 ** 18).toString();
          const Paraswap = `https://api.paraswap.io/prices/?srcToken=0xe77abb1e75d2913b2076dd16049992ffeaca5235&destToken=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&amount=${a}&srcDecimals=18&destDecimals=6&side=SELL&excludeDirectContractMethods=false&network=137&otherExchangePrices=true&partner=paraswap.io&userAddress=0x0000000000000000000000000000000000000000`;
          console.log("Paraswap", a);
          const getParaswapData = await axios.get(Paraswap);
          if (getParaswapData.status === 200) {
            let finalUSDTPrice = getParaswapData?.data?.priceRoute?.destAmount;
            finalUSDTPrice = (finalUSDTPrice / 10 ** 6).toFixed(2);
            console.log("finaldeod&&&&", finalUSDTPrice);
            setUsdtPrice(finalUSDTPrice);
          }
        } catch (error) {
          console.log("error in deod price paraswap api", error);
        }
      }
    }
    handleGetParaswapPrice();
  }, [totalToken]);

  return (
    <UserPageSection>
      <div>
        <div className="row">
          <div className="col-6">
            <button
              className="btn connect-btn mx-2 boobtn"
            // onClick={() => navigate("/stack-token")}
            >
              Stake Token 1
            </button>
          </div>
        </div>
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Staked Token</h6>
              </div>
              <p className="mt-1">{totalToken}</p>
              <p className="mt-1">
                <span>
                  <img src={deodLogo} className="deod-logo" alt="Deod" />
                </span>
                {totalToken}
                {"  "}Deod = {usdtPrice}
                <span>
                  <img src={UsdtLogo} className="deod-logo" alt="USDT" />
                </span>{" "}
                USDT
              </p>
            </div>
          </div>
          {/* <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Bonus Amount</h6>
              </div>
              <p className="mt-3">
                {(+finalTotalAmount - +totalToken).toFixed(2)}
              </p>
            </div>
          </div> */}
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Bonus Amount</h6>
              </div>
              <p className="mt-3">{(+finalTotalAmount - +totalToken).toFixed(2)}</p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Total Amount</h6>
              </div>
              <p className="mt-3">{(+finalTotalAmount).toFixed(2)}</p>
              {/* <p className="mt-3">09/06/2006 03:05:15 PM</p> */}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Referral Earnings</h6>
              </div>
              <p className="mt-3">{refferalEarnold}</p>

            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Available Referral Earnings </h6>
                <span>(Till Dec-08-2024)</span>
              </div>
              <p className="mt-3">{refferalEarn}</p>
              <div className="text-center">
              {ismintedOne.indexOf(address) != -1 ? (
                   <button
                   className="btn connect-btn"
              
                 >
                   Claim 
                 </button>
              ):(
                <button
                className="btn connect-btn"
              onClick={() => handleClaimReferral()}
              >
                Claim 
              </button>
              )}
             
                {/* <button
                  className="ms-1 btn connect-btn"
                  // onClick={() => handleClaim()}
                >
                  Claim Referral
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Staked + Bonus Released</h6>
                <span>(Dec-09-2024 Onwards)</span>
              </div>
              <p className="mt-3">{availableClaim}</p>
              <div className="text-center">
                <button
                  className="btn connect-btn"
                  onClick={() => handleClaim()}
                >
                  Claim 
                </button>
                {/* <button
                  className="ms-1 btn connect-btn"
                  // onClick={() => handleClaimReferral()}
                >
                  Claim Referral
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Total Withdrawal</h6>
              </div>
              <p className="mt-3">{withdrawnold + totalClaimToken + referralEarningsWithdrawn}</p>
            </div>
          </div>
          
        </div>
        <div class="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Staked Amount</th>
                <th scope="col">Bonus Amount</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Percentage</th>
                <th scope="col">Staking Date</th>
                <th scope="col">Release Date</th>
              </tr>
            </thead>
            <tbody>
              {stackingData &&
                stackingData?.map((value, key) => {
                  return (
                    <tr>
                      <th scope="row">{key + 1}</th>
                      <td>{value?.depositAmount}</td>
                      <td>{value?.bonusAmount}</td>
                      <td>{value?.totalAmount}</td>
                      <td>{value?.percentage}</td>
                      <td>{value?.depositDateFinal}</td>
                      <td>{value?.maturityDateFinal}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </UserPageSection>
  );
};

export default UserstakeOne;
