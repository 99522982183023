export const ismintedOne = [
  "0x6089b78055d5edbbb3bf75bd14fd43d610adcf38",
  // "0xf3597bc963b657203177e59184d5a3b93d465c94",

  "0xd6d51119ffe9ddf2068b0997d35ff9c4f1c8bb67",
  "0x78dac2dd4accd20e2ab67a93da2592aba0693077",

  "0x530f2c254284cb0ae98cac7cad135f97fe842cfe",
  "0x753622ddf7545c46dec392be89d323aa5fffab12",
  "0x82108fbb03388a6730bbccc6d7df1a6185078a4f",
  "0xd63f428736a3f68fe292a70ac263b9b1bb34cb1a",
  "0x4c312dd3f8912fdbb3462b407d7e829f7c6476d8",
  "0xa2361043c5624c89326872ebd7c597744782a618",
  "0x2dfbf3fceade4e3f62a8ed766df4d111b5c6a517",
  "0x3388c7eacc2a1a2a2921a79395323ef833576ede",
  "0xac6fd434345ca6fcfabae4e2fa0068e1c69b6d4d",
  "0x09388231bbf2a47ae7e2b84f583a446852fed911",
  "0x18a63c8ec5f19f79415020699e3a68683684d2ee",
  "0xd9b0999aafadb20c08b5b08642190a44cdb7c6a4",
  "0x55c5f9592d05cdd10c5b01b9e80eb1847b6ab479",
  "0x2ac4f24f2ba192a42105578598a397cbee0c1f01",
  "0xfddc204fab7324c128fd7cbce3ef18edb6e530cd",
  "0x369568cdf4fd64ab9a086a9e04c10e0ed05ae969",
  "0x8c8c05ac2f01d09320ddfc089332a79289a30f16",
  "0xc3f941d979547663831a5869c3e2cf98ac992ce4",
  "0x68ac4bf61a9c792b492d46fc4ee44fd0c4bbc08c",
  "0x935571f3c3429f8a23d7384e5aee10da0cafa4fc",
  "0x2ea4c2c0cb556c08ccc384032b329c522f4f7cc9",
  "0xa3c05bd6bc0d5ac4bb11cd177a49fe390d58a603",
  "0xb9b9602f8424bda42a578432dc98cb74cfc4dadd",
];

export const ismintedTwo = [
  // "0x8ca1a7a63427ac9685d876e5e4f6a82f214003e7",
  "0x7e0ee435de647774657daf5e787a0d0318a93b31",
  "0xf512c7fe4e149230b8f53250cb308367b73c50ab",
  "0xed41149fdcc50629aa7fa8896af27af94b474220",
  // "0xf3597bc963b657203177e59184d5a3b93d465c94",
  "0x0ac5e2bf5f10ccf75aab5ce1e3ea72c9b6a3642c",

  "0xd6d51119ffe9ddf2068b0997d35ff9c4f1c8bb67",
  "0x78dac2dd4accd20e2ab67a93da2592aba0693077",

  "0x530f2c254284cb0ae98cac7cad135f97fe842cfe",
  "0x753622ddf7545c46dec392be89d323aa5fffab12",
  "0x82108fbb03388a6730bbccc6d7df1a6185078a4f",
  "0xd63f428736a3f68fe292a70ac263b9b1bb34cb1a",
  "0x4c312dd3f8912fdbb3462b407d7e829f7c6476d8",
  "0xa2361043c5624c89326872ebd7c597744782a618",
  "0x2dfbf3fceade4e3f62a8ed766df4d111b5c6a517",
  "0x3388c7eacc2a1a2a2921a79395323ef833576ede",
  "0xac6fd434345ca6fcfabae4e2fa0068e1c69b6d4d",
  "0x09388231bbf2a47ae7e2b84f583a446852fed911",
  "0x18a63c8ec5f19f79415020699e3a68683684d2ee",
  "0xd9b0999aafadb20c08b5b08642190a44cdb7c6a4",
  "0x55c5f9592d05cdd10c5b01b9e80eb1847b6ab479",
  "0x2ac4f24f2ba192a42105578598a397cbee0c1f01",
  "0xfddc204fab7324c128fd7cbce3ef18edb6e530cd",
  "0x369568cdf4fd64ab9a086a9e04c10e0ed05ae969",
  "0x8c8c05ac2f01d09320ddfc089332a79289a30f16",
  "0xc3f941d979547663831a5869c3e2cf98ac992ce4",
  "0x68ac4bf61a9c792b492d46fc4ee44fd0c4bbc08c",
  "0x935571f3c3429f8a23d7384e5aee10da0cafa4fc",
  "0x2ea4c2c0cb556c08ccc384032b329c522f4f7cc9",
  "0xa3c05bd6bc0d5ac4bb11cd177a49fe390d58a603",
  "0xb9b9602f8424bda42a578432dc98cb74cfc4dadd",
];
export const ismintedThree = [
  // "0xf3597bc963b657203177e59184d5a3b93d465c94",
  // "0x9fddde84a575ee6777a24e5e21ce71417d0cdd28",
  // "0x6c1dee23ee76640dce6a48ef766840e6401d8a40",
  // "0x8ca1a7a63427ac9685d876e5e4f6a82f214003e7",
  "0x6089b78055d5edbbb3bf75bd14fd43d610adcf38",
  "0x8614e6909e8f920139e21e31ff63ff9a80348067",
  "0x530f2c254284cb0ae98cac7cad135f97fe842cfe",

  "0xd6d51119ffe9ddf2068b0997d35ff9c4f1c8bb67",
  "0x78dac2dd4accd20e2ab67a93da2592aba0693077",

  "0x753622ddf7545c46dec392be89d323aa5fffab12",
  "0x82108fbb03388a6730bbccc6d7df1a6185078a4f",
  "0xd63f428736a3f68fe292a70ac263b9b1bb34cb1a",
  "0x4c312dd3f8912fdbb3462b407d7e829f7c6476d8",
  "0xa2361043c5624c89326872ebd7c597744782a618",
  "0x2dfbf3fceade4e3f62a8ed766df4d111b5c6a517",
  "0x3388c7eacc2a1a2a2921a79395323ef833576ede",
  "0xac6fd434345ca6fcfabae4e2fa0068e1c69b6d4d",
  "0x09388231bbf2a47ae7e2b84f583a446852fed911",
  "0x18a63c8ec5f19f79415020699e3a68683684d2ee",
  "0xd9b0999aafadb20c08b5b08642190a44cdb7c6a4",
  "0x55c5f9592d05cdd10c5b01b9e80eb1847b6ab479",
  "0x2ac4f24f2ba192a42105578598a397cbee0c1f01",
  "0xfddc204fab7324c128fd7cbce3ef18edb6e530cd",
  "0x369568cdf4fd64ab9a086a9e04c10e0ed05ae969",
  "0x8c8c05ac2f01d09320ddfc089332a79289a30f16",
  "0xc3f941d979547663831a5869c3e2cf98ac992ce4",
  "0x68ac4bf61a9c792b492d46fc4ee44fd0c4bbc08c",
  "0x935571f3c3429f8a23d7384e5aee10da0cafa4fc",
  "0x2ea4c2c0cb556c08ccc384032b329c522f4f7cc9",
  "0xa3c05bd6bc0d5ac4bb11cd177a49fe390d58a603",
  "0xb9b9602f8424bda42a578432dc98cb74cfc4dadd",
];
export const ismintedFour = [
  // "0x8ca1a7a63427ac9685d876e5e4f6a82f214003e7",
  // "0xc3ee06980d4fc8fb9fb700a8cb8c3114ed46bdf7",
  "0xd6d51119ffe9ddf2068b0997d35ff9c4f1c8bb67",
  // "0xf3597bc963b657203177e59184d5a3b93d465c94",

  "0x78dac2dd4accd20e2ab67a93da2592aba0693077",

  "0x530f2c254284cb0ae98cac7cad135f97fe842cfe",
  "0x753622ddf7545c46dec392be89d323aa5fffab12",
  "0x82108fbb03388a6730bbccc6d7df1a6185078a4f",
  "0xd63f428736a3f68fe292a70ac263b9b1bb34cb1a",
  "0x4c312dd3f8912fdbb3462b407d7e829f7c6476d8",
  "0xa2361043c5624c89326872ebd7c597744782a618",
  "0x2dfbf3fceade4e3f62a8ed766df4d111b5c6a517",
  "0x3388c7eacc2a1a2a2921a79395323ef833576ede",
  "0xac6fd434345ca6fcfabae4e2fa0068e1c69b6d4d",
  "0x09388231bbf2a47ae7e2b84f583a446852fed911",
  "0x18a63c8ec5f19f79415020699e3a68683684d2ee",
  "0xd9b0999aafadb20c08b5b08642190a44cdb7c6a4",
  "0x55c5f9592d05cdd10c5b01b9e80eb1847b6ab479",
  "0x2ac4f24f2ba192a42105578598a397cbee0c1f01",
  "0xfddc204fab7324c128fd7cbce3ef18edb6e530cd",
  "0x369568cdf4fd64ab9a086a9e04c10e0ed05ae969",
  "0x8c8c05ac2f01d09320ddfc089332a79289a30f16",
  "0xc3f941d979547663831a5869c3e2cf98ac992ce4",
  "0x68ac4bf61a9c792b492d46fc4ee44fd0c4bbc08c",
  "0x935571f3c3429f8a23d7384e5aee10da0cafa4fc",
  "0x2ea4c2c0cb556c08ccc384032b329c522f4f7cc9",
  "0xa3c05bd6bc0d5ac4bb11cd177a49fe390d58a603",
  "0xb9b9602f8424bda42a578432dc98cb74cfc4dadd",
];
